
















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
/*
The loading spinner component is used on nearly every page which loads something from the backend.
 */
export default class LoadingSpinner extends Vue {
  // true if the loading is in progress. Set the loading value true at the start of the promise and to false on .finally
  @Prop()
  isLoading: boolean
}
