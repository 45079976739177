











































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BaseForm from '@/components/forms/BaseForm.vue'
import { ContactFormContent, Form } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import { Action, Getter } from 'vuex-class'
import { CompanyProfile } from '@/store/company/types'
import { SimpleServerResponse } from '@/store/types'
import { SEND_CONTACT_FORM } from '@/store/user/actions.type'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { GET_BASE_DATA_EDIT } from '@/store/student/actions'

@Component({
  components: { LoadingSpinner, BaseForm }
})
/*
The contact form component used in the contact page and the kode metering.
It can be used everywhere where the user might need to contact someone.
@group FORMS
 */
export default class ContactForm extends Vue {
  @Getter('isLoggedIn') isLoggedIn: boolean

  @Action(SEND_CONTACT_FORM)
  public sendContactForm: (data: CompanyProfile) => Promise<SimpleServerResponse>

  @Action(GET_BASE_DATA_EDIT)
  public getBaseInformation: () => Promise<SimpleServerResponse>

  isLoading = false
  sentSucceeded = false
  contactFormData: ContactFormContent = { email: '', firstname: '', lastname: '', subject: '', content: '' }

  sendMessage (): void {
    this.isLoading = true
    this.sendContactForm(this.contactFormData).then(() => {
      this.sentSucceeded = true
      this.$root.$emit('alert', this.$i18n.t('contact-form.message-send'))
    }).catch(error => {
      this.$root.$emit('alert', this.$i18n.t('contact-form.' + error.requestStatus), error.message, true)
    }).finally(() => {
      this.isLoading = false
    })
  }

  resetForm (): void {
    this.$root.$emit('dismiss-alert')
    this.sentSucceeded = false
    this.contactFormData = { email: '', firstname: '', lastname: '', subject: '', content: '' }
    this.loadData()
  }

  get fields (): Form[] {
    return [
      {
        id: 'email',
        key: 'email',
        type: InputTypes.EMAIL,
        required: true,
        label: this.$i18n.t('contact-form.email').toString() + ' *',
        autofocus: true,
        disabled: this.isLoading
      },
      {
        id: 'dummy',
        key: 'dummy',
        type: InputTypes.DUMMY
      },
      {
        id: 'firstname',
        key: 'firstname',
        type: InputTypes.TEXT,
        required: true,
        label: this.$i18n.t('contact-form.firstname').toString(),
        disabled: this.isLoading
      },
      {
        id: 'lastname',
        key: 'lastname',
        type: InputTypes.TEXT,
        required: true,
        label: this.$i18n.t('contact-form.lastname').toString(),
        disabled: this.isLoading
      },
      {
        id: 'subject',
        key: 'subject',
        type: InputTypes.TEXT,
        required: true,
        label: this.$i18n.t('contact-form.subject').toString(),
        disabled: this.isLoading
      },
      {
        id: 'messageContent',
        key: 'content',
        type: InputTypes.TEXTAREA,
        required: true,
        label: this.$i18n.t('contact-form.content').toString(),
        md: 12,
        disabled: this.isLoading
      }
    ]
  }

  loadData (): void {
    if (this.isLoggedIn) {
      this.getBaseInformation().then(data => {
        this.$set(this.contactFormData, 'email', data.content.email)
        this.$set(this.contactFormData, 'firstname', data.content.firstname)
        this.$set(this.contactFormData, 'lastname', data.content.lastname)
      })
    }
  }

  created (): void {
    this.loadData()
  }
}
