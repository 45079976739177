







import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import ContactForm from '@/components/forms/ContactForm.vue'

@Component({
  components: { ContactForm }
})
export default class ContactFormPage extends Vue {
}
